.Root {
  color: var(--color-figure);
}

.Accordion {
  background-color: var(--color-gray-4);
}

.Breadcrumb {
  margin-bottom: calc(var(--gutter) * 2);
}

.SearchSection {
  padding: 0;
}

.ContactSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: calc(var(--gutter) * 5);
}

.ContactSectionHeader {
  font-size: calc(var(--font-size-medium) * 1.3);
  line-height: calc(var(--font-size-medium) * 2);
  margin-bottom: calc(var(--gutter) * 2);
  margin-top: calc(var(--gutter) * 2);
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .SideBar {
    display: none;
  }

  .Breadcrumb {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }

  .Root .AccordionSection {
    padding-top: 0;
  }

  .ContactModalContent {
    width: 95%;
    height: 90%;
  }
}
