.Root {
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.Root.HasBottomBorder {
  padding-bottom: 10px;
}

.Button {
  /* background: none; */
  border: none;
  box-shadow: none;
  padding: 0;
  transform: skewX(-30deg);
  height: 100%;
  width: 50%;
  min-width: auto !important;
}

.Inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 20;
  transform: skewX(30deg);
  padding: var(--gutter);
  white-space: nowrap; /* prevent text from going on two lines */
}

.Shadow {
  box-shadow: 1px 10px 0px #06c167;
}
.Shadow:hover {
  box-shadow: 1px 10px 0px #07d672;
}

.LastButton:after {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 500px;
  height: 100%;
  transition: 0.15s ease-in;
}

.LastButton:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 100%;
  width: 125%;
  height: 4px;
}
/**
 * Splash
 */
.IsSplash {
  color: #ffffff;
  background: #ff6b06;
}
.IsSplash:hover {
  background: #f36300;
}
.IsSplash:hover:after {
  background: #f36300;
}
.LastButton.IsSplash:before {
  background: #ff6b06;
}
.LastButton.IsSplash:after {
  background: #ff6b06;
}
.GroupIsSplash .Shadow {
  box-shadow: 1px 8px 0px #ff6b06;
}

/**
 * Primary
 */

.IsPrimary {
  color: #ffffff;
  background: #06c167;
}
.IsPrimary:before {
  background: #019e53;
}
.IsPrimary:after {
  background: #06c167;
}
.IsPrimary:hover {
  background: #07d672;
}
.IsPrimary:hover:after {
  background: #07d672;
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .LastButton:before {
    height: 2px;
  }
  .Shadow {
    box-shadow: 1px 8px 0px #06c167;
  }
  .Shadow:hover {
    box-shadow: 1px 8px 0px #07d672;
  }
}
