.Root {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BulletIcon {
  margin-right: calc(var(--gutter) * 1.2);
  color: var(--color-brand);
  height: 5rem;
  fill: var(--color-brand);
}

.Bullet {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-right: calc(var(--gutter) * 4);

  &:last-child {
    margin-right: 0;
  }
}

.BulletIconContainer {
  width: 5.75rem;
  height: 5.75rem;
  margin-right: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TextClassContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.Text {
  font-size: calc(var(--font-size-medium) * 1.2);
}

.Text b {
  font-weight: var(--font-weight-extra-bold);
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .Root {
    flex-direction: column;
    align-items: flex-start;
  }

  .Bullet {
    justify-content: space-between;
    margin-right: 0;
    margin-bottom: calc(var(--gutter) * 0.5);

    br {
      display: none;
    }
  }

  .Text {
    flex: 1;
    font-size: calc(var(--font-size-small) * 1);
  }

  .TextClassContainer {
    margin-right: 0;
  }

  .BulletIconContainer {
    width: auto;
    height: auto;
    margin-right: 0;
  }

  .BulletIcon {
    min-width: auto;
    margin-right: var(--gutter);
    height: 2rem;

    path,
    circle {
      stroke-width: 3px;
    }
  }
}
