.Root {
  position: relative;
  min-height: 40rem;
}

.Root img {
  object-fit: cover;
}

.Box {
  z-index: 10;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
  background-color: var(--color-light);
  max-width: 30rem;
}

.Inner {
  position: relative;
  padding-bottom: 8rem;
}

.BoxContentWrapper {
  padding: calc(var(--gutter) * 1.5) calc(var(--gutter) * 2);
}

.Text {
  padding-top: var(--gutter);
}

.ButtonGroup {
  height: 8rem;
  position: absolute;
  bottom: -8px;
}
.Button {
  padding: calc(var(--gutter) * 2);
  /* flex: 1 1 0px; */
  /* flex-direction: column; */
  /* align-items: flex-start; */
  /* padding: calc(var(--gutter) * 2); */
  text-transform: uppercase;
  /* opacity: 1 !important; */
}

.ButtonInner {
  padding: 0;
  align-items: flex-start;
}

/* .ButtonBeforePrice {
  align-items: flex-start;
} */

.ButtonBeforePrice .Amount:before {
  content: ' ';
  height: 5px;
  width: 150%;
  background-color: var(--color-splash);
  position: absolute;
  transform: skewY(-22deg);
  bottom: 2.5rem;
  pointer-events: none;
}

.CampaignPriceWrapper {
  display: block;
}

.Amount {
  font-size: calc(var(--font-size-extra-large) * 1.5);
  line-height: 3rem;
  position: relative;
}

.Currency {
  font-size: calc(var(--font-size-medium) * 1.2);
  padding-left: calc(var(--gutter) * 0.25);
  text-transform: none;
}

.Button .ArrowClass {
  position: absolute;
  right: 2rem;
  z-index: 20;
  transform: skewX(30deg);
  /* transform: scale(2); */
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .Container {
    padding: 0;
  }
  .Root {
    min-height: 35rem;
  }

  .Box {
    left: 50%;
    transform: translateX(-50%);
    top: 5%;
    width: 85%;
  }

  .BoxContentWrapper {
    padding: calc(var(--gutter) * 1.5);
  }

  .Inner {
    padding-bottom: 5rem;
  }
  .ButtonGroup {
    height: 5rem;
  }

  .Button {
    font-size: 0.9rem;
  }

  .Amount {
    font-size: 2rem;
    line-height: 1.5rem;
  }

  .Button .ArrowClass {
    transform: scale(0.5) skewX(30deg);
    right: 0;
  }

  .ButtonBeforePrice .Amount::before {
    bottom: 1.2rem;
    height: 3px;
  }

  .ButtonGroup .Button,
  .BoxContentWrapper {
    padding: calc(var(--gutter) * 1.5);
  }
}
