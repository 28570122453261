.Root {
  display: flex;
  align-items: center;
  font-size: var(--font-size-medium);
}
.Icon {
  margin-right: calc(var(--gutter) * 0.5);
  height: 1.7rem;
  overflow: visible !important;
}

/* .IsInfo {
} */
