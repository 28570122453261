.Root {
  margin-bottom: var(--gutter);
  max-width: 90rem;
}
.PriceModelsWrapper {
  display: flex;
  justify-content: center;
  margin-top: calc(var(--gutter) * 3);
  overflow: hidden;
  padding: calc(var(--gutter) * 1.8) 0;
}

.WhiteBackground .PriceModelBox {
  background-color: var(--color-gray-4);
}

.SliderContainer {
  display: flex;
  position: relative;
}

/* 
.SliderPagination {
  display: none;
} */

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .Root {
    margin-bottom: calc(var(--gutter) * -0.2);
  }

  .SliderPagination {
    display: block;
    margin-top: calc(var(--gutter) * 2);
  }

  .PriceModelsWrapper {
    justify-content: inherit;
    margin-top: calc(var(--gutter) * 2);
    padding: calc(var(--gutter) * 1.8) 0;
  }
}
