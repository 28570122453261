.Dot {
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
  background-color: var(--color-brand);
  border-radius: 0.75rem;
}

.Dot,
.LoadingIcon {
  margin-right: calc(var(--gutter) * 0.5);
}

.IsLoading {
  display: none;
}

.StatusText {
  display: flex;
}

.LoadingIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.RedDot {
  background-color: var(--color-error);
}

.YellowDot {
  background-color: var(--color-warning);
}
