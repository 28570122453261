.Root {
  width: 1.3rem;
  height: 1.3rem;
  padding: 0;
  cursor: help;
  margin-left: calc(var(--gutter) * 0.5);
}
.Icon {
  height: 100%;
  width: 100%;
  color: var(--color-gray-3);
  filter: invert(83%) sepia(79%) saturate(3%) hue-rotate(327deg)
    brightness(109%) contrast(80%);
}

.TooltipBox {
  background-color: var(--color-light);
  color: var(--color-figure);
  font-size: var(--font-size-small);
  box-shadow: var(--box-shadow-medium);
  z-index: 20;
}

.IsShowing .Icon {
  color: var(--color-figure);
  filter: invert(0%) sepia(100%) saturate(7464%) hue-rotate(116deg)
    brightness(91%) contrast(107%);
}

.TooltipHeader {
  padding: var(--gutter);
  line-height: initial;
  background-color: var(--color-brand);
  color: var(--color-fill);
  font-size: calc(var(--font-size-medium) * 1.2);
}

.TooltipContent {
  padding: var(--gutter);
  font-weight: var(--font-weight-medium);
  line-height: initial;
  max-width: 14rem;
}

.Arrow {
  width: 0px;
  height: 0px;
  border-left: var(--arrow-size) solid transparent;
  border-right: var(--arrow-size) solid transparent;
}

.TooltipBox[data-popper-placement='top'] {
  margin-bottom: var(--gutter);

  .Arrow {
    border-top: var(--arrow-size) solid var(--color-light);
  }
}
.TooltipBox[data-popper-placement='bottom'] {
  margin-top: var(--gutter);

  .Arrow {
    top: calc(var(--arrow-size) * -1);
    border-bottom: var(--arrow-size) solid var(--color-fill);
  }
}
