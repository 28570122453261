.ContactModalContent {
  height: 80%;
  width: 70%;
}
.ContactIframe {
  width: 100%;
  height: 100%;
  border: 0;
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .ContactModalContent {
    width: 100%;
    height: 100%;
  }
}
