.Root {
  position: relative;
  background-image: url(/assets/icons/cluster.svg);
  background-size: cover;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-light);
  font-size: calc(var(--font-size-medium) * 0.8);
  font-weight: var(--font-weight-extra-bold);
}
