.Root {
  position: relative;
  /* min-height: 60rem; */
}

.BackgroundImage {
  position: relative;
  height: 30rem;

  img {
    object-fit: cover;
  }
}

.Content {
  position: relative;
  background-color: var(--color-fill);
  width: 60vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  margin-top: -17rem;
  padding-bottom: var(--section-padding);
}

.IconWrapper {
  background-color: var(--color-fill);
  width: 11rem;
  margin-top: -3rem;
  padding: 2.5rem;
  border-radius: 8rem;
}

.Inner {
  width: 50%;
  text-align: center;
}

.Header {
  margin-bottom: calc(var(--gutter) * 2.5);
  margin-top: var(--gutter);
}

.InputWrapper {
  width: 100%;
  margin-bottom: var(--gutter);

  input {
    width: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.Checkbox {
  align-items: center;
  margin-top: calc(var(--gutter) * 2);
  color: var(--color-gray-2);
  text-align: initial;
  ul {
    list-style: disc;
    list-style-position: inside;
    margin: 0;
    padding: calc(var(--gutter) * 0.5) var(--gutter);
  }
  a {
    color: #000 !important;
    border-bottom: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    text-decoration: underline !important;
  }
  p {
    margin-bottom: 0;
  }
}

.Button {
  margin-top: calc(var(--gutter) * 2);
}

.DisclaimerText {
  color: var(--color-gray-2);
  margin: auto;
  margin-top: calc(var(--gutter) * 2);
  width: 75%;
}
.DisclaimerLink {
  color: var(--color-gray-2);
  margin-top: calc(var(--gutter) * 1);
  display: block;
}

/**
 * Desktop only
 */

@media (--viewport-mobile-up) {
  .Content {
    max-width: inherit;
    padding-left: 0;
    padding-right: 0;
  }
}

/*
 * For mobile
 */

@media (--viewport-mobile-down) {
  .BackgroundImage {
    height: 13rem;
  }

  .IconWrapper {
    width: 6rem;
    margin-top: -1.7rem;
    padding: 1.5rem;
    border-radius: 8rem;
  }

  .Content {
    margin-top: 0;
    width: 100%;
  }

  .Inner {
    width: 100%;
  }

  .Checkbox,
  .Button {
    margin-top: 0;
  }

  .Button {
    width: 100%;
    justify-content: space-between;
  }

  .DisclaimerText,
  .DisclaimerLink {
    width: 100%;
    font-size: 0.9rem;
  }
}
