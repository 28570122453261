.Root {
  background-color: var(--color-light);
  flex: 1 1 0px;
  position: relative;
  padding-bottom: 3.5rem;
}

.Wrapper {
  padding: calc(var(--gutter) * 1.5);
  position: relative;

  &:not(:first-child) {
    border-top: 3px solid var(--color-gray-4);
  }
}

.WrapperInner {
  display: flex;
  flex-direction: column;
}

.WrapperInnerBottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gutter);

  .Inner {
    flex: 1;
    min-height: calc(var(--gutter) * 2.5);
  }
}

.WhiteBackground .Wrapper {
  &:not(:first-child) {
    border-top: 3px solid var(--color-light);
  }
}

.MutedText {
  font-size: var(--font-size-extra-small);
  line-height: calc(var(--gutter) * 1.1);
  color: var(--color-gray-2);
  display: flex;
  white-space: pre;
}

.InformationText {
  margin-top: calc(var(--gutter) * 0.5);
  font-size: var(--font-size-extra-small);
  line-height: calc(var(--gutter) * 1.1);
  color: var(--color-gray-2);
}

.WrapperSeperator {
  position: absolute;
  left: -6px;
  right: -3px;
  background-color: var(--color-gray-4);
  height: 3px;
  width: 105%;
  top: -10px;
  display: none;
}

.PriceModelName {
  color: var(--color-brand);
  font-size: calc(var(--font-size-medium) * 1.5);
  line-height: calc(var(--font-size-medium) * 1.5);
  /* display: inline-block; */
  /* float: left; */
}

.InfoIcon {
  display: inline-flex;
}

.PriceWrapper {
  display: flex;
  margin: var(--gutter) 0;
  margin-top: var(--gutter);
}

.Price {
  font-size: calc(var(--font-size-extra-large) * 1.6);
  line-height: calc(var(--font-size-extra-large) * 1.6);
  font-weight: var(--font-weight-extra-bold);
}

.PriceInterval {
  font-weight: var(--font-weight-extra-bold);
  align-self: flex-end;
  padding-left: calc(var(--gutter) * 0.2);
  padding-top: calc(var(--gutter) * 0.2);
}

.PriceIntervalBusiness {
  font-weight: var(--font-weight-extra-bold);
  align-self: flex-start;
  padding-left: calc(var(--gutter) * 0.2);
  margin-top: -1rem;
}

.BelowPrice {
  font-size: var(--font-size-extra-small);
  line-height: calc(var(--gutter) * 1.1);
}

.IsPopularLabel {
  transform: skewX(30deg);
  text-align: center;
  font-weight: 600;
  height: calc(var(--gutter) * 1.8);
  color: var(--color-light);
  font-size: var(--font-size-small);
  display: flex;
  align-items: center;
  justify-content: center;
}

.IsPopularWrapper {
  width: calc(var(--gutter) * 7);
  background-color: #ff6b06;
  transform: skewX(-30deg);
  position: absolute;
  right: -8px;
  height: calc(var(--gutter) * 1.8);
}

.IsPopularContainer {
  position: absolute;
  width: 100%;
  right: 0;
  top: -28px;
  height: calc(var(--gutter) * 1.8);
  display: inline-flex;
  justify-content: flex-end;
  overflow: hidden;
}

.FeatureList {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: var(--font-size-extra-small);
  width: 100%;
  margin-bottom: var(--gutter);
  line-height: calc(var(--gutter) * 1.1);
}

.FeatureList li {
  margin-bottom: calc(var(--gutter) * 0.3);
  display: flex;
  align-items: center;
}

.FeatureList li svg {
  margin-right: calc(var(--gutter) * 0.5);
}

.MissingFeature {
  color: var(--color-gray-3);

  svg path {
    stroke: var(--color-gray-3);
  }
}

.ButtonGroup {
  position: absolute;
  bottom: -6px;
  z-index: 30;
  height: 4rem;
}

.ButtonClass {
  min-width: inherit;
  font-size: var(--font-size-medium);
  /*padding: 1.1rem 1.1rem;*/
  /*position: relative;
  flex-basis: 50%;  */
}

.ButtonClassDisabled {
  background-color: var(--color-light);
  cursor: default;

}

.Muted {
  color: var(--color-gray-2);
  font-weight: var(--font-weight-bold) !important;
}

.Root:not(:last-child) {
  margin-right: calc(var(--gutter) * 1.5);
}

.Root.IsPopular:before {
  height: 100%;
  content: ' ';
  position: absolute;
  top: 0;
  z-index: 31;
  display: block;
  width: 100%;
  left: 0;
  /* box-shadow: inset 0px 0px 0px 4px var(--color-brand); */
  border: 4px solid var(--color-brand);
  pointer-events: none;
}

.IsCampaign.IsPopular:before {
  box-shadow: inset 0px 0px 0px 2px var(--color-splash);
}

.WhiteBackground .ButtonClass:first-child {
  background-color: var(--color-gray-4);
}

.Root.IsPopular .ButtonClass:last-child {
  z-index: 10;
}

.CampaignPriceWrapper {
  font-size: var(--font-size-extra-small);
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
}

.CampaignButtonInner {
  align-items: flex-start;
}

.BeforeCampaignPrice {
  position: relative;
  display: inline-block;
  font-size: calc(var(--font-size-medium) * 1.1);
  text-align: left;
}

.BeforeCampaignPrice:before {
  content: ' ';
  height: 3px;
  width: 80%;
  background-color: var(--color-splash);
  position: absolute;
  transform: skewY(347deg);
  pointer-events: none;
  bottom: 0;
  top: 10px;
  right: 0;
  left: 0;
}

.VatText {
  font-size: 0.75rem;
  line-height: 1.1rem;
  font-weight: var(--font-weight-extra-bold);
}

.priceDecimal {
  font-weight: var(--font-weight-extra-bold);
  align-self: flex-start;
  padding-left: calc(var(--gutter) * 0.2);
  padding-top: calc(var(--gutter) * 0.2);
  flex-basis: 100%;
  font-size: 1.25rem;
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
}

.flexBreak {
  flex-basis: 100%;
  height: 0;
}

/**
 * For medium screen size
 */

@media (max-width: 1165px) {
  .Root {
    flex: 0 0 40%;
  }

  .IsPopularContainer {
    top: -25px;
  }
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .Root {
    flex: 0 0 32%;
    margin-right: var(--gutter) !important;
    min-width: 13rem;
  }
}

/**
 * For very mobile (e.g. iPhone 5S)
 */

@media (--viewport-small-mobile-down) {
  .Root {
    /* ensure user can see next price model in slider */
    min-width: 17rem;
    flex: 0 0 30%;
  }
}
