.Root {
  position: absolute;
  z-index: 10;
  right: var(--gutter);
  top: var(--gutter);
  width: 22rem;
  background-color: var(--color-fill);
  height: calc(var(--gutter) * 3);
  transition: 0.5s all;
}

.FirstButton,
.SecondButton {
  font-weight: var(--font-weight-medium);
  transition: 0.5s all, font-weight 0s;
}

.FirstButtonActive {
  background-color: var(--color-brand);
}

.FirstButtonActive .FirstButton {
  background-color: var(--color-brand);
  color: var(--color-fill);
  font-weight: var(--font-weight-extra-bold);
}

.FirstButtonActive .SecondButton {
  color: var(--color-figure);
}

.FirstButtonActive .SecondButton:after {
  background-color: var(--color-fill);
}

.SecondButtonActive .SecondButton {
  font-weight: var(--font-weight-extra-bold);
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .Root {
    bottom: var(--gutter);
    top: inherit;
    left: var(--gutter);
    width: calc(100% - calc(var(--gutter) * 2));
  }
}
