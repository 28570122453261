/* stylelint-disable no-descending-specificity */
:root {
  --grid-margin: calc(var(--gutter) * 1.5);
}

.Root {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.Button {
  min-width: 22rem;
}

/* .GridHeader {
  margin-bottom: var(--section-padding);
} */

.GridBoxHeader {
  margin: calc(var(--gutter) * 0.5) 0;
  margin-bottom: calc(var(--gutter) * 0.2);
}

.MaxThreePerRow .GridBoxHeader {
  font-size: var(--font-size-large);
}

.GridWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: calc(var(--section-padding) * 0.7);
  height: 100%;
}

.GridWrapper.DefaultLayout {
  max-width: 80rem;
}

.GridWrapper.SingleGridBox {
  justify-content: center;

  a {
    flex-grow: 0.5;
  }
}

.GridButtonWrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: var(--section-padding);
}

.GridBox {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  position: relative;
}

.MaxThreePerRow .GridWrapper {
  flex-wrap: wrap;
  justify-content: center;
}

/* 
.BoxedLayout .GridWrapper {
  margin-top: calc(var(--gutter) * 2);
} */

.GridBoxLink {
  display: block;
  text-transform: uppercase;
  color: var(--color-brand);
  text-decoration: none;
  font-weight: var(--font-weight-extra-bold);
  font-size: var(--font-size-extra-small);
  margin-top: var(--gutter);
}

.MaxThreePerRow .GridBox {
  flex: 0 0 30%;
  margin-bottom: var(--grid-margin);
}

.MaxThreePerRow .GridBox,
.BoxedLayout .GridBox {
  &:not(.SingleGridBox) {
    margin-right: var(--grid-margin);
  }
}

.HasArrows .GridBox {
  margin-right: 0;
}

.BoxedLayout .GridBox {
  background-color: var(--color-light);
  text-align: left;
  border-bottom: 2px solid #d3d3d3;
  padding: var(--grid-margin);
  text-decoration: none;
  /* min-height: 18rem; */
  min-height: 13rem;

  a {
    display: block;
    margin-top: var(--gutter);
  }
}

.BoxedLayout .GridBoxLink {
  margin-top: auto;
}

.BoxedLayout .GridBoxContent {
  margin-bottom: var(--gutter);
}

.DefaultLayout .GridBox {
  padding: var(--gutter) 0;
  flex: 0 0 33.33%;
  min-width: 33%;

  &:last-child {
    margin-right: 0;
  }
}

.WhiteBackground.BoxedLayout .GridBox {
  background-color: var(--color-gray-4);
}

.GridBoxImage {
  position: relative;

  img {
    object-fit: contain;
  }
}

.DefaultLayout .GridBoxImage {
  height: calc(var(--gutter) * 7);
  width: 100%;
  position: relative;

  img {
    object-position: bottom;
  }
}

.BoxedLayout .MaxImagesize {
  height: calc(var(--gutter) * 20) !important;

  img {
    object-fit: cover !important;
  }
}

.BoxedLayout .GridBoxImage {
  height: 4rem;
  margin-bottom: var(--gutter);
  margin-top: var(--gutter);
  /* height: calc(var(--gutter) * 20); */
  width: 100%;
  position: relative;

  img {
    object-position: center;
  }
}

.HasArrows .GridBox:not(:last-child):after {
  position: absolute;
  content: ' ';
  border-right: 0.3rem solid var(--color-gray-3);
  border-bottom: 0.3rem solid var(--color-gray-3);
  width: 2.5rem;
  height: 2.5rem;
  transform: rotate(-45deg) translateY(-50%);
  right: -3rem;
  top: 50%;
}

.GridBoxText {
  flex: 1;
  line-height: calc(var(--font-size-medium) * 1.2);
}

/* .GridBoxContent {
  display: flex;
  flex-direction: column;
} */

.GridBoxArrow {
  display: none;
}

/**
 * For mobile
 */
@media (--viewport-mobile-down) {
  .DefaultLayout .GridWrapper {
    display: flex;
    margin-top: 0;
    align-items: center;
  }

  .GridHeader {
    margin-bottom: calc(var(--gutter) * 1.5);
  }

  .DefaultLayout .GridHeader {
    margin-bottom: calc(var(--gutter) * 2);
  }

  .GridButtonWrapper {
    margin-top: calc(var(--gutter) * 1.5);
  }

  .HideTextOnMobile .GridBoxText {
    display: none;
  }

  /*
   * Boxed Layout
   */
  .BoxedLayout .GridBox {
    margin-right: 0;
    min-height: auto;
    flex-direction: row;
    justify-content: space-between;
    margin-top: var(--gutter);
    padding: var(--gutter);
    align-items: center;

    &:first-child {
      margin-top: 0;
    }
  }

  .BoxedLayout .GridWrapper {
    width: 100%;
  }

  .Root.HideTextOnMobile .GridBoxHeader {
    margin: 0;
    margin-left: var(--gutter);
  }

  .HideTextOnMobile .GridBox .GridBoxLink {
    display: none;
  }

  .HideTextOnMobile .GridBox .GridBoxText {
    display: none;
  }

  .HideTextOnMobile .GridBoxImage {
    margin-top: 0;
  }

  .BoxedLayout .GridBoxArrow {
    display: flex;
    align-items: center;

    path {
      stroke: var(--color-brand);
      stroke-width: 3px;
    }
  }

  .BoxedLayout .GridBoxContent {
    flex: 1;
    margin-bottom: 0;
  }

  .BoxedLayout .GridBoxImage {
    width: 3rem;
    margin-bottom: 0;
  }

  /**
   * Boxed layout when not hiding text
   */
  .BoxedLayout:not(.HideTextOnMobile) {
    .GridBox {
      flex-direction: column;
    }

    .GridBoxLink {
      margin-top: var(--gutter);
    }

    .GridBoxImage {
      margin-top: 0;
    }
  }

  /**
   * With arrows
   */
  /* .HasArrows .GridBox .GridBoxText {
    display: none;
  } */

  /* stylelint-disable-next-line */
  .HasArrows .GridBox {
    margin-bottom: calc(var(--gutter) * 3);

    /* stylelint-disable-next-line */
    &:last-child {
      margin-bottom: 0;
    }
  }

  .HasArrows .GridWrapper .GridBox:after {
    border-width: 0.2rem;
    width: 2rem;
    height: 2rem;
    right: 0;
    left: 0;
    top: inherit;
    bottom: calc(var(--gutter) * -2.5);
    transform: rotate(45deg);
    margin-left: auto;
    margin-right: auto;
  }

  /**
   * Max three per row
   */
  .MaxThreePerRow.BoxedLayout .GridBox {
    margin-bottom: 0;
    flex-direction: row-reverse;
    align-items: center;
  }

  /* .MaxThreePerRow.BoxedLayout .GridBox .GridBoxText {
    display: block;
  } */

  .MaxThreePerRow.BoxedLayout .GridBoxHeader {
    font-size: var(--font-size-medium);
    margin-left: 0;
    margin-top: 0;
  }

  .MaxThreePerRow.BoxedLayout .GridBoxLink {
    display: none;
  }

  .MaxThreePerRow.BoxedLayout .GridBox .GridBoxArrow {
    display: none;
  }

  .MaxThreePerRow.BoxedLayout .GridBoxImage {
    min-height: 3.5rem;
    width: 3.5rem;
    margin-left: var(--gutter);
  }

  /**
   * Default layout
   */

  .DefaultLayout .GridBox {
    padding: var(--gutter) 0;
    flex: 0 0 50%;

    &:first-child {
      flex: 0 0 100%;
    }
  }

  /**
   * Default layout (Grid On Mobile)
   */
  .DefaultLayout.GridOnMobile .GridWrapper {
    flex-wrap: wrap;
    flex-direction: row;
  }

  .DefaultLayout.GridOnMobile .GridBox {
    flex: 0 0 50%;
    margin-bottom: calc(var(--gutter) * 2);
  }
}
