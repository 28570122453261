/* .Root {
} */

.SearchIcon {
  height: 1.3rem;
  margin-left: 1rem;
  vertical-align: auto;

  path {
    fill: var(--color-gray-2);
  }
}

/**
 * For mobile
 */
/* 
@media (--viewport-mobile-down) {
  .ValueContainer div:first-of-type {
    overflow: hidden;
    width: 70%;
    white-space: nowrap;
  }
} */
