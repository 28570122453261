.Root {
  color: var(--color-figure);
  text-align: left;
  padding: var(--gutter);
  margin-bottom: var(--gutter);
  border-bottom: 1px solid var(--color-gray-3);
  position: relative;
  width: 100%;
  background-color: var(--color-gray-4);
}

.WhiteBackground {
  background-color: var(--color-light);
}

.Header {
  display: flex;
  text-decoration: none;
  font-weight: var(--font-weight-extra-bold);
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
}

.HeaderItem {
  flex: 1;
  &:last-child {
    padding-right: var(--gutter);
  }
}

.ToggleWrapper {
  margin-left: var(--gutter);
}

.Toggle {
  position: relative;
  width: 16px;
  height: 16px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: var(--color-brand);
    transition: transform 0.25s ease-out;
  }

  /* Vertical line */
  &:before {
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
  }

  /* horizontal line */
  &:after {
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    margin-top: -1px;
  }
}

.AccordionContent {
  display: none;
  color: var(--color-gray-2);
  margin-top: var(--gutter);
}

.IsOpen .Toggle {
  cursor: pointer;
  &:before {
    transform: rotate(90deg);
  }
  &:after {
    transform: rotate(180deg);
  }
}

.IsOpen .AccordionContent {
  display: block;
}

.IsOpen .Header {
  color: var(--color-brand);
}

.ArrowWrapper {
  height: 25px;
  width: 25px;
}

.Arrow path {
  stroke: var(--color-brand);
}

.FlippedIcon .Header {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.FlippedIcon .Arrow {
  margin-right: var(--gutter);
  transform: rotate(180deg);
  path {
    stroke: var(--color-figure);
  }
}

.HashLink {
  height: var(--layout-site-header-height);
  position: absolute;
  top: calc(var(--layout-site-header-height) * -2);
  visibility: hidden;
}
