.Root {
  padding: calc(var(--gutter) * 0.3) calc(var(--gutter) * 0.5);
  text-transform: uppercase;
  font-weight: var(--font-weight-extra-bold);
  font-size: calc(var(--font-size-extra-small) * 1);
  letter-spacing: 0.05rem;
}

.IsTransperant {
  border: 1px solid var(--color-figure);
}

.IsSplash {
  background-color: var(--color-splash);
  border-bottom: 1px solid var(--color-splash-border);
  color: var(--color-light);
}

.IsDark {
  background-color: #5b5b5b;
  border-bottom: 1px solid #2d2d2d;
  color: var(--color-light);
}
