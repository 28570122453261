.Root {
  display: flex;
  position: relative;
  flex: 1;
  max-width: 20rem;
  background-color: var(--color-fill);
  border-bottom: 2px solid var(--color-gray-3);
  margin-right: calc(var(--gutter) * 1.2);
  flex-direction: column;
  &:last-child {
    margin-right: 0;
  }
}

.GetPositionButton {
  min-width: auto;
  margin-top: var(--gutter);
}

.GrayBackground.Root {
  background-color: var(--color-gray-4);
}

.Root:not(.WithImage) {
  cursor: pointer;
  justify-content: center;
}

.ContentWrapper {
  padding: var(--gutter);
  display: flex;
  flex: 1;
}

.LeftSide,
.RightSide {
  display: flex;
  justify-content: center;
}

.LeftSide {
  flex: 1;
  flex-direction: column;
}

.RightSide {
  color: var(--color-brand);
  font-weight: var(--font-weight-extra-bold);
  margin-left: var(--gutter);
  align-items: center;
}

.RightSideInner {
  display: flex;
}

.LocationName {
  font-weight: var(--font-weight-extra-bold);
}

.LocationGoogleLink {
  font-size: var(--font-size-small);
  color: var(--color-gray-2);
}

.LocationAddress {
  font-size: var(--font-size-small);
  color: var(--color-gray-2);
}

.WithImage {
  border-bottom: 0;
  padding-bottom: 4rem;
}

.WithImage .ContentWrapper {
  flex-direction: column;
  justify-content: space-between;
}

.WithImage .RightSide {
  margin-left: 0;
  justify-content: flex-start;
}

.WithImage .LeftSide {
  flex: 0;
}

.LocationImage {
  height: 11rem;
  position: relative;
  width: 100%;
  overflow: hidden;

  img {
    object-fit: cover;
  }
}

.Label {
  position: absolute;
  z-index: 20;
  transform: rotate(-45deg);
  width: 10rem;
  text-align: center;
  left: -2.9rem;
  top: 1.3rem;
}

.ComingSoonLabel {
  font-size: calc(var(--font-size-extra-small) * 0.7);
}

.ButtonGroup {
  height: 4rem;
  position: absolute;
  bottom: -8px;
}

.MarkerIcon {
  width: calc(var(--gutter) * 0.75);
  margin-right: calc(var(--gutter) * 0.2);
}

/**
 * Skeleton loading for 
 */
/* without image */
.IsSkeleton .RightSide span {
  width: 2rem;
}

.IsSkeleton .LocationName,
.IsSkeleton .LocationAddress,
.IsSkeleton .RightSide span {
  height: 1em;
}

.IsSkeleton .LocationName,
.IsSkeleton .LocationAddress,
.IsSkeleton .LocationImage,
.IsSkeleton .RightSide span {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;

  margin-bottom: var(--gutter);
  &:last-child {
    margin-bottom: 0;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/* with image */
.WithImage.IsSkeleton {
  .LocationImage {
    margin-bottom: 3px;
  }
  .LocationName {
    width: 75%;
    margin-bottom: 0.5rem;
  }
  .LocationAddress {
    width: 80%;
  }
  .RightSide span {
    margin-top: 0.7rem;
    width: 20%;
  }
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .Root {
    flex: 0 0 80%;
    /* min-width: 20rem; */
  }

  .WithImage {
    flex: 0 0 80%;
  }

  .Button {
    font-size: 0.9rem;
  }

  /* .IsSkeleton.Root {
    flex: 0 0 70%;
  } */
}
