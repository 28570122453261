.Root {
  color: var(--color-figure);
  height: 45rem;
  position: relative;
}

.Text {
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: var(--gutter);
}

.MapIcon {
  filter: drop-shadow(0 2px 14px rgba(0, 0, 0, 0.14));
}

.Button {
  margin-top: calc(var(--gutter) * 2);
  min-width: 15rem;
}

.BackgroundImage {
  background-image: url('/assets/images/map.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  filter: blur(10px);
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .Root {
    height: 35rem;

    /* make it sharp, instead of blurry, in the edges */
    margin-top: -5px;
    overflow: hidden;
    background-color: var(--color-gray-3);
  }

  .Text {
    justify-content: flex-start;
    margin-top: var(--gutter);
  }

  .BackgroundImage {
    filter: blur(2px);
    &:before {
      position: absolute;
      content: ' ';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(232, 232, 232, 0.8);
    }
  }
}
