:root {
  --content-padding: calc(var(--gutter) * 6);
  --primary-heading-line-height: 4.5rem;
}

.Root {
  /* padding: 0; */
  /* max-width: 95rem; */
  display: flex;
  flex-direction: column;
  /* min-height: 80vh; */

  /* height: 80vh; */
  /* min-height: 40rem; */
}

.Header {
  padding-right: calc(var(--gutter) * 1.2);
  margin-bottom: var(--gutter);
}

.HasBlackImageFade {
  background-color: var(--color-figure);
  position: relative;
  color: var(--color-fill);
  &:before {
    background-color: var(--color-figure);
    position: absolute;
    width: 150%;
    height: 100%;
    left: -25%;
    right: -25%;
    content: ' ';
  }
}

.Inner {
  display: flex;
  position: relative;
  flex: 1;
  /* min-height: 45rem; */
}

.ContentContainer {
  padding: calc(var(--gutter) * 3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* max-width: calc(var(--layout-container-width) / 2); */
}
/* 
.ContentWrapper {
  max-width: 35rem;
} */

.MapContainer {
  min-height: 100%;
  width: 50vw;
  position: relative;
}
.MapHidden {
  display: none;
}

.ImageContainer {
  position: relative;

  img {
    object-fit: cover;
  }
}

.HasBlackImageFade .ImageContainer:before {
  position: absolute;
  left: 0;
  right: -30%;
  top: 0;
  bottom: 0;
  content: ' ';
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.06906512605042014) 100%
  );
  box-shadow: inset 26px 12px 37px 21px rgba(0, 0, 0, 0.91);
  z-index: 20;
}

.HasBlackImageFade .ImageContainer {
  flex-basis: 70%;
}

.Text {
  font-size: calc(var(--font-size-medium) * 1.2);
  line-height: calc(var(--font-size-medium) * 1.55);
}

/**
 * Theme splash
 */

.Root.Splash em,
.Root.Splash h2 {
  color: var(--color-splash);
}

/**
 * Text Position
 */

.TextPositionRight .ContentContainer {
  text-align: right;
  align-items: flex-end;
}

.TextPositionCenter .ContentContainer {
  align-items: center;
  text-align: center;
}

/**
 * Image positions
 */

.ImagePositionLeft,
.ImagePositionRight {
  /* .ContentContainer {
    max-width: 33rem;
  } */

  .ContentContainer,
  .ImageContainer {
    flex-basis: 50%;
  }
}

.ImagePositionRight {
  flex-direction: row-reverse;
}

.ImagePositionLeft .ContentContainer {
  padding-right: 0;
}
.ImagePositionRight .ContentContainer {
  padding-left: 0;
}
/* 
.ImagePositionRight {
} */

.ImagePositionFullWidth {
  .ContentContainer,
  .ImageContainer {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .ImageContainer:after {
    box-shadow: inset 2px -87px 80px 3px rgba(0, 0, 0, 0.23);
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    content: ' ';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }

  .ContentContainer {
    z-index: 2;

    color: var(--color-light);
  }
}

/**
 * Buttons
 */

.ButtonGroup {
  display: flex;
  margin-top: calc(var(--gutter) * 1.5);
}

.ButtonGroup .Button {
  margin-top: calc(var(--gutter) * 0.5);
  justify-content: space-between;
  flex: auto;
  min-width: 19rem;
  &:not(:last-child) {
    margin-right: calc(var(--gutter) * 0.5);
  }
}

/* .HasButtons {
} */

.CheckList {
  display: inline-flex;
  font-size: var(--font-size-medium);
  flex-wrap: wrap;
  width: 100%;
  margin-top: calc(var(--gutter) * 2);
}

.CheckList li {
  margin-bottom: var(--gutter);
  display: flex;
  align-items: center;
  flex-basis: calc(50% - calc(var(--gutter) * 2));
  margin-right: calc(var(--gutter) * 2);
}

.CheckList li svg {
  margin-right: var(--gutter);
}

.Subtext {
  margin-top: calc(var(--gutter) * 2);
}

.AddressPickerWrapper {
  display: flex;
  margin-top: calc(var(--gutter) * 2);
}
.AddressPickerInput {
  flex: 1;
  margin-right: calc(var(--gutter) * 0.75);
}
.AdressPickerSubmit {
  min-width: 15rem;
}

/**
 * Rotating words
 */

.ReplacingWord {
  display: inline-block;
  /* height: var(--primary-heading-line-height); */
  perspective: 300px;
  transition: width 1.2s;
}

.FirstVisibleReplacingWord {
  animation-duration: 0;
}

.ReplacingWord span.ReplacingWordIsVisible {
  position: relative;
  opacity: 1;
  transform: rotateX(0deg);

  &:not(.FirstVisibleReplacingWord) {
    animation: rotate-word-in 1.2s;
  }
}

span.ReplacingWordIsHidden {
  position: absolute;
  transform: rotateX(180deg);
  animation: rotate-word-out 1.2s;
}

.ReplacingWord span {
  opacity: 0;
  transform-origin: 50% 100%;
  transform: rotateX(180deg);
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  word-break: keep-all;
}

@keyframes rotate-word-in {
  0% {
    transform: rotateX(180deg);
    opacity: 0;
  }
  35% {
    transform: rotateX(120deg);
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  100% {
    transform: rotateX(360deg);
    opacity: 1;
  }
}

@keyframes rotate-word-out {
  0% {
    transform: rotateX(0deg);
    opacity: 1;
  }
  35% {
    transform: rotateX(-40deg);
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  100% {
    transform: rotateX(180deg);
    opacity: 0;
  }
}

@media (--viewport-desktop-down) {
  .ButtonGroup {
    display: flex;
    flex-direction: column;
    margin-top: calc(var(--gutter) * 1.5);
    max-width: calc(var(--gutter) * 23);
  }

  .Root .Inner .ButtonGroup .Button {
    margin-top: calc(var(--gutter) * 0.5);
    justify-content: space-between;
    /* min-width: 19rem; */
    margin-right: calc(var(--gutter) * 0.5);
    max-width: initial;
  }
}

/*
 * Non-mobile
 */

@media (--viewport-mobile-up) {
  .ImageContainer div {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .ImageContainer div:first-child {
    left: -30% !important;
    max-width: 60rem !important;
  }
  .ImagePositionRight .ImageContainer div:first-child {
    left: 0 !important;
    right: -30% !important;
  }

  .HasBlackImageFade {
    min-height: 40rem;
  }

  .HasBlackImageFade .ContentContainer {
    padding-top: calc(var(--gutter) * 8);
  }
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .Root {
    padding: 0;
    min-height: auto;
    height: auto;
  }
  .Root .Inner {
    flex-direction: column;
  }

  .ImageContainer div {
    display: block;
    position: relative;
    height: inherit;
  }

  .Root .ImageContainer {
    flex-basis: auto;
    height: 15rem;
  }

  .Root .ContentContainer {
    padding: calc(var(--gutter) * 2);
  }

  .Root .Text {
    font-size: 1.12rem;
    line-height: 1.7rem;
  }

  /**
   * Address picker & Button
   */

  .Button,
  .AdressPickerSubmit {
    margin-right: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  /**
   * Address picker 
   */
  .AddressPickerWrapper {
    flex-direction: column;
  }
  .AddressPickerInput {
    margin-right: 0;
  }
  .AdressPickerSubmit {
    margin-top: var(--gutter);
  }

  /**
   * Black faded
   */

  .HasBlackImageFade .ImageContainer:before,
  .HasBlackImageFade.Root:before {
    display: none;
  }

  .HasBlackImageFade .ImageContainer {
    margin-top: var(--layout-site-header-height-mobile);
  }

  /**
   * With map
   */

  .MapContainer {
    width: 100%;
    min-height: auto;
    height: 25rem;
  }

  .HasMap .Inner {
    flex-direction: column-reverse;
  }
}
