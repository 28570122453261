.Root {
  display: flex;
  justify-content: center;
}

.FAQSearchWrapper {
  display: flex;
  width: 50%;
}
.SearchInput {
  flex: 1;
}

.Root .FAQSearchWrapper .SearchButton {
  width: 5rem !important;
}

.SearchIcon {
  fill: var(--color-light);
  width: 1rem;
  margin-left: 0;
}
.NeedMoreHelpButton {
  margin-top: calc(var(--gutter) * 0.5);
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .FAQSearchWrapper {
    width: 100%;
  }
}
