.Root {
  display: flex;
}

.ContentWrapper {
  flex: 1;
  padding-right: var(--section-padding);
}

.Heading {
  margin-bottom: calc(var(--gutter) * 2);
}

.Text {
  margin-bottom: calc(var(--gutter) * 2);
  font-size: var(--font-size-medium);
}

.QuestionWrapper {
  flex: 1;
}

.MobileButton {
  display: none;
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .Root {
    flex-direction: column;
    align-items: flex-start;
  }

  .ContentWrapper {
    padding-right: 0;
    text-align: center;
  }

  .Button {
    display: none;
  }

  .MobileButton {
    display: flex;
    margin: auto;
    margin-top: var(--gutter);
  }
}
