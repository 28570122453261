.Root {
  text-align: center;
  position: relative;
}

.Dot {
  display: inline-block;
  height: 0.8rem;
  width: 0.8rem;
  background-color: var(--color-gray-3);
  margin-right: calc(var(--gutter) * 0.5);
  border-radius: 1rem;
  transition: all 0.5s;
  overflow: hidden;
  padding: 0; /* required for iOS/safari */

  &:last-child {
    margin-right: 0;
  }
}

.CurrentDot {
  background-color: var(--color-dark);
  width: 2rem;
}

/* hack to avoid minor page jumping between loading states */
.IsSkeleton {
  margin-top: 24px !important;
  margin-bottom: 31px !important;
}

.IsSkeleton .Dot {
  position: relative;
  background-color: #dddbdd;
  height: 0.8rem;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
