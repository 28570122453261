.Root {
  color: var(--color-figure);
}

.HeroText {
  margin-top: 1em;
  font-size: var(--font-size-medium);
}

.IconsWithText {
  display: flex;
  /* justify-content: space-between; */
  line-height: calc(var(--font-size-medium) * 1.125);
}

.Alert {
  margin-top: calc(var(--gutter) * 0.5);
  padding: var(--gutter);
  background-color: var(--color-gray-4);
  font-weight: var(--font-weight-extra-bold);
}

.IconWrapper {
  font-size: var(--font-size-small);
  display: flex;
  align-items: center;
}

.IconWrapper:not(:last-child) {
  margin-right: var(--gutter);
}

.Icon {
  height: 1rem;
  margin-right: calc(var(--gutter) * 0.5);
  color: var(--color-dark);
}

.StatusDot {
  display: flex;
  align-items: center;
  font-weight: var(--font-weight-medium);
  margin-left: var(--gutter);
}
.StatusDotInGrid {
  justify-content: center;
  margin-top: var(--gutter);
}

.GridTextSeperator {
  color: var(--color-brand);
  padding: 0 calc(var(--gutter) * 0.5);
}

.IconGroup {
  position: absolute;
  z-index: 30;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(var(--gutter) * 2);
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .IconWrapper {
    font-size: 1.1rem;
    margin-bottom: calc(var(--gutter) * 0.5);

    &:last-child {
      margin-bottom: 0;
    }
  }
  .IconsWithText {
    flex-direction: column;
    line-height: calc(var(--font-size-medium) * 1.3);
  }

  .StatusDot {
    flex: 1;
  }

  .GridTextSeperator {
    display: block;
    font-size: 0;
  }

  .Icon {
    min-width: calc(var(--gutter) * 1.5);
  }
}
