.Root {
  /* stylelint-disable selector-pseudo-class-no-unknown */
  & :global {
    /* a {
      color: #000;
    } */
  }
}

.ImageWrapper {
  max-width: 15rem;
  margin-bottom: calc(var(--gutter) * 2);
}

.Button {
  min-width: 22rem;
}

.IsHalfWidth {
  width: 50%;
  margin: auto;
  margin-bottom: var(--gutter);
}

.Text,
.ButtonGroup,
.SubText {
  margin-top: calc(var(--gutter) * 1.5);
}

.ButtonGroup {
  text-align: center;
  a {
    margin-right: calc(var(--gutter) * 0.5);

    &:last-child {
      margin-right: 0;
    }
  }
}

.TextPositionCenter {
  .SubText,
  .Text {
    text-align: center;
  }
}
.TextPositionRight {
  .SubText,
  .Text {
    text-align: right;
  }
}

.TextPositionCenter .ImageWrapper {
  margin-left: auto;
  margin-right: auto;
}

.HeaderCenter .ImageWrapper {
  margin-left: auto;
  margin-right: auto;
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .IsHalfWidth {
    width: 100%;
  }

  .Button {
    min-width: 15rem;
  }
}
