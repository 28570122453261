.Root:not([type='radio']) {
  background-color: var(--color-gray-4);
  color: var(--color-figure);
  box-shadow: inset 0px -1px 0px 0px var(--color-gray-3);
  border-bottom: 1px solid var(--color-gray-3);
  padding: 0 calc(var(--gutter) * 0.8);
}

.Root.FullWidth {
  width: 100%;
}

.Root.HasError {
  border: 2px solid var(--color-error);
  /* border-bottom-color: var(--color-error); */
  box-shadow: none;
}

.Root.IsDisabled {
  color: var(--color-gray-2);
}

.Root::placeholder {
  color: #bfbfbf;
}

.Tooltip {
  svg {
    height: 1.3rem;
    width: 1.3rem;
  }
}

.InputWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--gutter);
  width: 100%;
  .UnderLabel {
    font-size: var(--font-size-small);
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
  }
}

.react-datepicker__input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--gutter);
}

.InputWrapper .Root:not(:first-of-type) {
  margin-top: var(--gutter);
}

.Label {
  min-width: 10rem;
  font-weight: var(--font-weight-extra-bold);
  font-size: var(--font-size-medium);
  margin-bottom: 0.5rem;
  display: flex;
}

.AddonWrapper {
  position: relative;
  width: 100%;
}

/* 
.InputWrapper .Root {
} */

.ValidationError,
.ValidationError a {
  color: var(--color-error);
  font-size: var(--font-size-small);
  margin-top: calc(var(--gutter) * 0.5);
  text-align: left;
}

/* Checkmark for input fields*/
.IsValid {
  background-image: url(/assets/icons/checkmark-circle-filled.svg);
  background-size: 13px;
  background-repeat: no-repeat;
  background-position: right 1rem center;
}

.HasError {
  background-image: url(/assets/icons/exclamation-mark.svg);
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: right 1rem center;
}

.PasswordWrapper {
  position: relative;
}

.PasswordEye {
  position: absolute;
  right: 40px;
  bottom: 13px;
  height: auto;
  width: auto;
  color: var(--color-gray-2);
}

.PasswordEye:hover {
  cursor: pointer;
}

/**
 * Sizes
 */
.ExtraSmall {
  min-height: 1.8rem;
}
.Small {
  min-height: 2rem;
}
.Medium {
  min-height: 3.2rem;
}
.Large {
  min-height: 3.5rem;
}
.ExtraLarge {
  min-height: 4rem;
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .Root {
    padding: var(--gutter);
  }

  .PasswordEye {
    bottom: 9px;
  }
}
