.Root {
  margin-right: calc(var(--gutter) * 2);
  width: 15rem;
}

.Link {
  display: flex;
  align-items: center;
  margin-bottom: var(--gutter);
  text-decoration: none;
  font-weight: var(--font-weight-extra-bold);
}

.GoBackAccordion {
  background-color: var(--color-gray-4);
}

.ActiveLink {
  color: var(--color-brand);
}

.IconWrapper {
  position: relative;
  width: 3rem;
  height: 3rem;
  margin-right: var(--gutter);
}

.LinkIcon {
  object-fit: contain;
}

.MobileMenu {
  display: none;
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .Root {
    width: 100%;
    margin-right: 0;
    margin-bottom: var(--container-padding-mobile);
  }
  .HasMobileMenu {
    &.Root {
      margin-left: calc(var(--container-padding-mobile) * -1);
      width: calc(100vw - 2rem);
      padding-bottom: var(--section-padding-mobile);
    }
    .MenuItems {
      margin-bottom: calc(var(--gutter) * 2);
      background-color: var(--color-gray-4);
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s;
    }

    &.Root,
    .MenuItems {
      margin: 0;
      margin-right: calc(var(--container-padding-mobile) * -1);
      margin-left: calc(var(--container-padding-mobile) * -1);
    }

    .MenuItemsInner {
      padding: var(--section-padding-mobile) var(--container-padding-mobile);
      margin-left: 2rem;
    }

    .MobileMenu {
      text-decoration: none;
      background-color: var(--color-gray-4);
      border-bottom: 2px solid var(--color-gray-3);
      font-weight: var(--font-weight-extra-bold);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc(var(--font-size-medium) * 1.1);
      min-width: 100%;
      min-height: auto;
      box-sizing: content-box;
      padding: var(--gutter);
    }

    .Chevron {
      margin-left: calc(var(--gutter) * 0.5);
      transform: scale(0.7);
      transition: transform 0.5s;
    }

    &.IsOpen .Chevron {
      transform: scale(0.7) rotate(180deg);
    }

    &.IsOpen .MenuItems {
      max-height: 1000px;
      border-bottom: 1px solid var(--color-gray-3);
    }
  }
}
