.BackgroundColorWhite {
  background-color: var(--color-light);
}

.BackgroundColorGrey {
  background-color: var(--color-gray-4);
}

.Hero {
  overflow-x: hidden;
}
