/* .Root {
} */

.SearchInputWrapper {
  border-radius: 0;
  position: absolute;
  z-index: 10;
  width: 25rem;
  left: var(--gutter);
  top: var(--gutter);
  height: calc(var(--gutter) * 3);
}

.SearchInputInner {
  position: relative;
}
.MarkerIcon {
  position: absolute;
  height: 2rem;
  width: 2rem;
  color: var(--color-gray-2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.MarkerIcon svg {
  height: 1rem;
  width: 1rem;
}
/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .SearchInputWrapper {
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }
}
