.Root {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  flex: 1;
}

@media (--viewport-mobile-down) {
  .Root {
    min-height: 50vh;
  }
}
