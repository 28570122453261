.Root {
  text-align: center;
}

.Accordion {
  margin-top: calc(var(--gutter) * 2);
}

.SecondButton {
  margin-top: var(--gutter);
}
