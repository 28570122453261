.Container {
  display: flex;
  align-items: flex-start;
}

.Label {
  display: flex;
  cursor: pointer;
}

.LabelText {
  flex: 1;
  margin-left: 0.5rem;
  line-height: 1.2;
  font-weight: var(--font-weight-medium);
}

.Checkbox {
  display: inline-block;
  margin-top: 0.1rem;
  border: 1px solid var(--color-gray-2);
  width: calc(var(--gutter) * 1.2);
  height: calc(var(--gutter) * 1.2);
  transition: all 150ms;
  background: var(--color-gray-4);

  svg {
    visibility: hidden;
    fill: none;
    stroke: white;
    stroke-width: 3px;
  }
}

.IsErrored {
  border: 2px solid var(--color-error);
}

.IsChecked {
  background: var(--color-brand);
  border-bottom-color: var(--color-button-primary-border);
  border-width: 0;
  svg {
    visibility: visible;
  }
}

.HiddenCheckbox {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
