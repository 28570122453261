.Root {
  box-shadow: var(--box-shadow-medium);
  display: none;
  position: fixed; /* Stay in place */
  z-index: 110; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.IsShowing {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Content {
  background-color: var(--color-fill);
  color: var(--color-figure);
  width: 60%; /* Could be more or less, depending on screen size */
  min-height: 20rem;
  position: relative;
  animation-name: animatetop;
  animation-duration: 0.4s;
  max-height: 35rem;
  overflow-y: auto;
  padding: 3rem 2rem;
  overflow-x: hidden;
}

.BottomModalClass {
  background-color: var(--color-fill);
  color: var(--color-figure);
  width: 100%;
  min-height: fit-content;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  max-height: 35rem;
  overflow-y: auto;
}

.Inner {
  width: 100%;
  height: 100%;

  h1,
  h2 {
    margin-right: 1rem;
  }
}

.BottomModalClass .Inner {
  padding: calc(var(--gutter) * 1.3) calc(var(--gutter) * 1.6);
}

.ImageWrapper {
  position: relative;
  flex-basis: 50%;
}

.ImageCover .ImageWrapper img {
  object-fit: cover;
}

.HasImage .Content {
  display: flex;
  gap: 2rem;
}

.HasImage .Inner {
  flex-basis: 50%;
}

.Close {
  min-width: 2.5rem;
  height: 2.5rem;
  background-color: var(--color-fill);
  z-index: 130;
  border-radius: 3.5rem;
  cursor: pointer;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 7.5px;
  right: 7.5px;
}

.Close:hover {
  transform: rotate(180deg);
}

/* Animation for bottom modals */

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOutToBottom {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

.slideInFromBottom {
  animation-name: slideInFromBottom;
  animation-duration: 0.25s;
}

.slideOutToBottom {
  animation-name: slideOutToBottom;
  animation-duration: 0.25s;
}

/* Animation for centered modals */
@keyframes animatetop {
  from {
    top: -300px;
  }
  to {
    top: 0;
  }
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .Content {
    width: 90%;
  }

  .HasImage .Content {
    flex-direction: column;
  }

  .ImageWrapper {
    height: 10rem;
    flex-basis: auto;
    order: -1;
  }
}
