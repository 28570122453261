.Text {
  margin-top: var(--gutter);
  text-align: center;
}

.MapWrapper {
  margin-top: var(--section-padding);
  img {
    width: 100%;
  }
}

.MapContainer {
  height: 40rem;
  position: relative;
}

.LocationBoxesWrapper {
  display: flex;
  padding-bottom: calc(var(--section-padding) * 0.5);
  overflow: hidden;
  justify-content: center;
}

.SliderContainer {
  display: flex;
  padding-bottom: 0.5rem;
}

.SliderPagination {
  display: none;
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .SliderPagination {
    display: block;
    margin-top: calc(var(--gutter) * 2);
  }

  .MapContainer {
    margin: 0 calc(var(--container-padding-mobile) * -1);
  }

  .LocationBoxesWrapper {
    display: inherit;
  }

  .MapWrapper {
    margin-top: calc(var(--gutter) * 2);
  }
}
