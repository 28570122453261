.Root {
  margin-bottom: var(--gutter);
}

.BreadCrumb {
  list-style: none;
}

.BreadCrumb li {
  display: inline;
}

/**
 * For non-mobile
 */

@media (--viewport-mobile-up) {
  .BreadCrumb li + li:before {
    padding: 1rem;
    color: black;
    content: '/\00a0';
  }
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .BreadCrumb {
    text-align: left;
  }
  .BreadCrumb li {
    display: block;
    height: 24px;

    &:before {
      padding: 0;
      padding-right: var(--gutter);
      content: ' \2192';
    }
  }
}
