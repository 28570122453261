:root {
  --Location-Information-Width: 11rem;
  --Marker-Size: 2rem;
}

.Root {
  position: relative;
  background-image: url(/assets/icons/washworld-marker.svg);
  background-size: cover;
  height: var(--Marker-Size);
  width: var(--Marker-Size);
  margin-left: calc(var(--Marker-Size) * -0.5);
  margin-top: calc(var(--Marker-Size) * -0.5);
}

.Root.IsClickable {
  cursor: pointer;
}

/* .New {
  background-image: url(/assets/icons/washworld-marker-new.svg);
} */

.ComingSoon {
  background-image: url(/assets/icons/washworld-marker-coming-soon.svg);
}
