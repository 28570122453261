/* .Root {
}*/
.Icon {
  width: 5rem;
}
.Header {
  margin: 0 0 var(--gutter) 0;
}

.NameAndZip {
  display: flex;
  gap: var(--gutter);
}

.NameAndZip .NameInput {
  flex: 1;
}

.NameAndZip .ZipInput {
  flex: 1;
}

.EmailInput {
  width: 100%;
}

.Checkbox {
  margin-top: var(--gutter);
  ul {
    list-style-type: disc;
    list-style-position: inside;
    margin: 0;
    padding: calc(var(--gutter) * 0.5) var(--gutter);
  }
  a {
    color: #000 !important;
    border-bottom: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    text-decoration: underline !important;
  }
  p {
    margin-bottom: 0;
  }
}

.MutedText {
  margin-top: var(--gutter);
  color: var(--color-gray-2);
  font-size: var(--font-size-small);
  display: block;
}

/*
 * For mobile
 */

@media (--viewport-mobile-down) {
  .Header {
    margin-top: 0;
    text-align: center;
  }

  .Icon {
    width: 3rem;
  }

  .NameAndZip {
    display: flex;
    flex-direction: column;
  }

  .SubmitButton {
    width: 100%;
  }
}
